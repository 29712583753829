import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import BagIcon from "../../../Assets/Images/bag-blue.svg";
import PhoneIcon from "../../../Assets/Images/phone-blue.svg";
import EmailIcon from "../../../Assets/Images/email-blue.svg";
import AddressIcon from "../../../Assets/Images/marker-blue.svg";
import TimeIcon from "../../../Assets/Images/clock-blue.svg";
import WebIcon from "../../../Assets/Images/website-blue-icon.svg";
import PinIcon from "../../../Assets/Images/pin-blue.svg";

import { Upload, UploadFileInfo } from "@progress/kendo-react-upload";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import defaultProfilePic from "../../../Assets/Images/profile-default-img.svg";
import {
  getContactFullName,
  maskPhoneNumber,
  formatAddressRender,
} from "../../../utils/utils";
import { allContactStatusView } from "../../../constant/constant";
import { setToastNotification } from "../../../redux/action/ToastNotificationAction";
import useAxios, { AxiosService } from "../../../service/Service";
import Loader from "../../../components/common/Loader";
import moment from "moment-timezone";
import { URLS } from "../../../utils/url";
import { fetchTripCountByClientId } from "../../../service/contact";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { getSalesmateContactUrl } from "@/utils/salesmate"

const fileStatuses = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

interface IGetTotalCompletedTrips {
  "Charter": number,
  "Jet Card": number
}

export default function UserLeftBar({
  contactNotes,
  isProfileUploadPermission,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isOpened, setIsOpened] = useState(window.innerWidth < 991);
  const [files, setFiles] = useState([]);
  const [events, setEvents] = useState<any>([]);
  const [filePreviews, setFilePreviews] = useState<any>({});
  const [affectedFiles, setAffectedFiles] = useState([]);
  const [contactAddress, setContactAddress] = useState<string>("");
  const [timeZone, setTimeZone] = useState<string>("");
  const [contactPreferredPhoneNumber, setContactPreferredPhoneNumber] =
    useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [businessNameAndTitle, setBusinessNameAndTitle] = useState<string>("");
  const [contactFullName, setContactFullName] = useState<string>("");
  const [profileImage, setProfileImage] = useState<any>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pinnedNotes, setPinnedNotes] = useState([]);

  const params = useParams();
  const dispatch = useDispatch();

  const [completedTrips, setCompletedTrips] = React.useState<{
    jetCard: number;
    charted: number;
  }>({
    jetCard: 0,
    charted: 0,
  });

  const GetTotalCompletedTrips = async () => {
    fetchTripCountByClientId(params.contactId, (response: IGetTotalCompletedTrips) => {
      setCompletedTrips({
        charted: response?.["Charter"] ?? 0,
        jetCard: response?.["Jet Card"] ?? 0
      })
    })
  };

  useEffect(() => {
    GetTotalCompletedTrips();
  }, []);

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

  useEffect(() => {
    const pinnedNotesArray = contactNotes?.filter(
      (note: any) => note.pinned && !note.deleted
    );
    const sortedPinnedNotes = pinnedNotesArray?.sort((a: any, b: any) => {
      const dateA = moment(a.modifiedDate);
      const dateB = moment(b.modifiedDate);
      if (dateA.isBefore(dateB)) {
        return 1;
      } else if (dateB.isBefore(dateA)) {
        return -1;
      } else {
        return 0;
      }
    });
    setPinnedNotes(sortedPinnedNotes);
  }, [contactNotes]);

  const { personalInformation, contactInformation, brokerInformation } =
    useSelector((state: any) => state.contacts);

  useEffect(() => {
    affectedFiles
      .filter((file: any) => !file.validationErrors)
      .forEach((file: any) => {
        const reader = new FileReader();
        reader.onloadend = (ev: any) => {
          setFilePreviews({
            ...filePreviews,
            [file.uid]: ev.target.result,
          });
        };
        if (file && file.getRawFile) {
          reader.readAsDataURL(file.getRawFile());
        }
      });
  }, [affectedFiles, filePreviews]);

  const addSeparation = useCallback((value: any) => {
    return value ? "," : "";
  }, []);

  useEffect(() => {
    if (contactAddress.length > 0) {setTimeZoneFormat(contactAddress);}
  }, [contactAddress]);

  const getTimezoneOffset = (timeZone: string) => {
    const now: any = moment();
    const timeZoneOffset = now.tz(timeZone).format("Z");
    return `(UTC ${timeZoneOffset})`;
  };

  const setTimeZoneFormat = async (address: string) => {
    const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
    const response = await fetch(`${URLS.GetLatLng}${address}&key=${API_KEY}`);
    const result = await response.json();

    if (result.status === "OK") {
      const lat = result.results[0].geometry.location.lat;
      const lng = result.results[0].geometry.location.lng;

      fetch(
        `${URLS.GetTimezone}${lat},${lng}&timestamp=${Math.floor(
          Date.now() / 1000
        )}&key=${API_KEY}`
      )
        .then((response) => response.json())
        .then((data: { timeZoneId: string; timeZoneName: string }) => {
          if (data?.timeZoneId && data?.timeZoneName) {
            setTimeZone(
              `${getTimezoneOffset(data.timeZoneId)} ${data.timeZoneName} (${data.timeZoneId
              })`
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error(
        `Geocode was not successful for the following reason: ${result.status}`
      );
    }
  };

  useEffect(() => {
    const tempContactAddress = contactInformation?.contactAddress?.address
      ?.filter((addr: any) => addr.preferred)
      ?.at(0);
    if (tempContactAddress) {
      setContactAddress(formatAddressRender(tempContactAddress));
    }
    const preferredPhone = contactInformation?.phone?.find((item: any) => item.preferred === true);
    setContactPreferredPhoneNumber(maskPhoneNumber(preferredPhone?.phone || '', preferredPhone?.countryCode || ''));
    const prefferedMail = contactInformation?.contactInformation?.emails.filter(
      (item: any) => item.preferred === true
    );
    setContactEmail(prefferedMail?.length ? prefferedMail[0].email : "");

    setBusinessNameAndTitle(personalInformation?.businessName || "");

    setContactFullName(getContactFullName(personalInformation));
  }, [
    contactAddress,
    contactInformation,
    personalInformation,
    contactPreferredPhoneNumber,
    contactEmail,
    businessNameAndTitle,
    brokerInformation,
    addSeparation,
  ]);

  const toggleDialog = () => {
    setIsOpen(!isOpen);
    setVisible(!visible);
  };

  function toggle() {
    setIsOpened((wasOpened) => !wasOpened);
  }

  const onAdd = (event: any) => {
    setFiles(event.newState);
    setEvents([...events, `File selected: ${event.affectedFiles[0].name}`]);
    setAffectedFiles(event.affectedFiles);
  };
  const onRemove = (event: any) => {
    let newFilePreviews = {
      ...filePreviews,
    };
    event.affectedFiles.forEach((file: any) => {
      delete newFilePreviews[file.uid];
    });
    setFiles([]);
    setEvents([...events, `File removed: ${event.affectedFiles[0].name}`]);
    setFilePreviews(newFilePreviews);
  };
  const onProgress = (event: any) => {
    setFiles(event.newState);
    setEvents([...events, `On Progress: ${event.affectedFiles[0].progress} %`]);
  };
  const onStatusChange = useCallback(
    (event: any) => {
      const file = event.affectedFiles[0];
      setFiles(event.newState);
      setEvents([
        ...events,
        `File '${file.name}' status changed to: ${fileStatuses[file.status]}`,
      ]);
    },
    [files, events]
  );

  const onCopyEmailHandler = () => {
    dispatch(setToastNotification({ type: "success", message: "copied!" }));
  };

  const OnRemoveImageHandler = async () => {
    setLoading(true);
    const { response } = await useAxios({
      url: `/contacts/profileimage/${params.contactId}`,
      method: "delete",
    });

    if (response.status === true) {
      FetchProfileImage();
      setFiles([]);
      setFilePreviews({});
    }
    setLoading(false);
  };

  async function onRemoveImageClickHandler(
    files: UploadFileInfo[],
    options: {
      formData: FormData;
      requestOptions: any;
    }
  ): Promise<{
    uid: string;
  }> {
    return { uid: "" };
  }

  async function onUploadImageClickHandler(
    files: UploadFileInfo[],
    options: {
      formData: FormData;
      requestOptions: any;
    },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{
    uid: string;
  }> {
    if (files && files[0] && files[0].getRawFile) {
      const file = files[0].getRawFile();
      try {
        await UploadProfileImage(file);
      } catch (e) {
        console.log(e);
      }
    }
    return {
      uid: "",
    };
  }

  const UploadImageToS3 = async (file: File, url: string) => {
    const response = await fetch(url, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type,
      },
    });

    if (response.ok) {
      return true;
    } else {
      console.error("Failed to upload file:", response.statusText);
      return false;
    }
  };

  const UploadProfileImage = async (file: File) => {
    setLoading(true);
    const fileName = String(Object(file).name).split(".")[0];
    const response = await AxiosService().put(
      `/contacts/profileimage/upload/${params.contactId}`,
      {
        fileName: fileName,
      }
    );
    if (response.status === 200) {
      const AWS_SIGNED_URL = response.data?.responseData.signedUrl;
      await UploadImageToS3(file, AWS_SIGNED_URL);
      FetchProfileImage();
    }
    setLoading(false);
  };

  const FetchProfileImage = async () => {
    const URL = `/contacts/profileimage/preview/${params.contactId}`;
    const { response, status } = await useAxios({
      url: URL,
      method: "get",
    });

    if (status === 200) {
      setProfileImage(response.responseData);
    }
  };

  useEffect(() => {
    if (params.contactId) {
      FetchProfileImage();
    }
  }, [params.contactId]);

  return (
    <>
      <div
        className="col-lg-4 col-xl-3"
        data-testid="contact-detail-left-container"
      >
        <div className="userSortInfoCard">
          <div className="userPicTitleSec">
            <div className="uploadUserPic">
              <img src={profileImage ?? defaultProfilePic} alt="profile-pic" />
              {isProfileUploadPermission && (
                <Button
                  className="uploadProfilePic"
                  data-testid="toggleDialog"
                  onClick={toggleDialog}
                />
              )}
            </div>
            <div className="uploadTopInfoSec">
              <label>
                ID #{params.contactId || ""} | {personalInformation?.contactType || ""}
              </label>
              <Tooltip
                content={(props) => (
                  <>
                    {contactFullName}
                  </>
                )}
                position="bottom"
              >
                <h4 title={contactFullName}>{contactFullName}</h4>
              </Tooltip>
              <div className="contactStatusTypeDetails">
                <div className="leadBtn contactStatus">
                <span className={`status ${personalInformation?.contactStatus}`}></span>
                  {allContactStatusView[personalInformation?.contactStatus]}
                </div>
              </div>
            </div>
            <Button
              togglable={true}
              className="profileSortInfoToggleBtn"
              onClick={toggle}
            ></Button>
          </div>
          {!isOpened && (
            <>
              <hr />
              <div className="userContactInfo">
                {personalInformation?.contactType === "Business" && (
                  <p>
                    <img src={WebIcon} alt="weICon" />
                    <label>
                      {personalInformation?.businessWebsite ? (
                        <a
                          href={personalInformation?.businessWebsite}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {personalInformation?.businessWebsite}
                        </a>
                      ) : (
                        "-"
                      )}
                    </label>
                  </p>
                )}
                <p>
                  <img src={BagIcon} alt="bgICon" />
                  <label>{businessNameAndTitle}</label>
                </p>
                <p>
                  <img src={PhoneIcon} alt="phoneIcon" />
                  <label>
                    {contactPreferredPhoneNumber}
                  </label>
                </p>
                <p>
                  <img src={EmailIcon} alt="email" />
                  {contactEmail && (
                    <label>
                      <p style={{ wordBreak: "break-word" }}>
                        <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                        <CopyToClipboard
                          onCopy={onCopyEmailHandler}
                          text={contactEmail}
                        >
                          <span className="copyLink" />
                        </CopyToClipboard>
                      </p>
                    </label>
                  )}
                </p>
                <p>
                  <img src={AddressIcon} alt="addressICon" />
                  <label>{contactAddress}</label>
                </p>

                <p>
                  <img src={TimeIcon} alt="timeICon" />
                  <label>{timeZone || ""}</label>
                </p>
              </div>
              <hr />
              <div className="userTripInfoSec">
                <p>
                  <span data-testid="contact-detail-lc-completed-trips">
                    Completed Trips
                  </span>
                  <label>
                    {completedTrips.charted} Charter / {completedTrips.jetCard} Jet Card
                  </label>
                </p>
                <hr />
                <p>
                  <span data-testid="contact-detail-lc-broker">Broker(s)</span>
                  <label>
                    {brokerInformation?.broker?.brokers?.map(
                      (item: any, index: number) => (
                        <strong key={index}>
                          {item.fullName}
                          <br />
                        </strong>
                      )
                    )}
                  </label>
                </p>
                <p>
                  <span data-testid="contact-detail-lc-assistant">
                    Assistant
                  </span>
                  <label>
                    {brokerInformation?.assistant?.assistant?.map(
                      (item: any, index: number) => (
                        <strong key={index}>{item.fullName}</strong>
                      )
                    )}
                  </label>
                </p>
              </div>
              { personalInformation?.salesmateContactId !== null && (
                <div className="userSalesmateLink">
                  <hr />
                  <p>
                    <span>Goose ID:</span>&nbsp;
                    <a target="_blank" href={getSalesmateContactUrl(personalInformation.salesmateContactId)}>{ personalInformation.salesmateContactId }</a>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        <div className="pinned-notes-wrapper">
          {pinnedNotes.map((noteItem: any) => {
            let { note, modifiedDate } = noteItem;
            const date = moment.utc(modifiedDate).local();
            const formattedDate = date.format("MMM D, YYYY h:mm A");
            return (
              <div className="pinned-notes-section">
                <section className="pinnednotes-icon">
                  <img src={PinIcon} alt="PinIcon" width={16} />
                </section>
                <section className="pinnednotes-description">
                  <p style={{ whiteSpace: "pre-wrap" }} className="pinnednote-text">{note}</p>
                  <p className="pinnednote-date">{formattedDate}</p>
                </section>
              </div>
            );
          })}
        </div>
      </div>
      {/* UPLOAD PROFILE IMAGE MODEL */}
      {visible && (
        <Dialog
          title="UPLOAD PROFILE IMAGE"
          onClose={toggleDialog}
          className="uploadProfileImgModel"
        >
          {loading && <Loader />}
          <div className="profilePicUploadOuter">
            <Upload
              className="uploadProfileImg noCrossBtn"
              batch={false}
              multiple={false}
              files={files}
              onAdd={onAdd}
              onRemove={onRemove}
              onProgress={onProgress}
              onStatusChange={onStatusChange}
              withCredentials={false}
              restrictions={{
                allowedExtensions: [".jpg", ".png"],
              }}
              saveUrl={onUploadImageClickHandler}
              removeUrl={onRemoveImageClickHandler}
              autoUpload={false}
            />
            <div className="uploadImageHints">
              <h4>
                Drag & Drop or <strong>Click to Browse File</strong>
              </h4>
              <p>Supports JPG, PNG up to 100mb</p>
            </div>

            {files.length ? (
              <div className="uploadedProfileImg">
                {Object.keys(filePreviews).map((fileKey, index) => (
                  <img className="rounded-circle" src={filePreviews[fileKey]} alt="" />
                ))}
              </div>
            ) : (
              <div className="uploadedProfileImg">
                <img className="rounded-circle" src={defaultProfilePic} alt="" />
              </div>
            )}
          </div>
          <div className="modelFooterAction">
            <Button themeColor="light" onClick={OnRemoveImageHandler}>
              Remove Profile Image
            </Button>
            <Button
              themeColor="primary"
              onClick={toggleDialog}
              data-testid="saveChanges"
            >
              Save Changes
            </Button>
          </div>
        </Dialog>
      )}
      {/* Model end */}
    </>
  );
}
